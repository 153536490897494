/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// bootsrap
import 'bootstrap/dist/css/bootstrap.min.css'

// styles template
// styles template
import '../css/owl.carousel.min.css'
import '../css/owl.theme.default.min.css'
import '../css/aos.css'
import '../css/style.css'

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, changeHeader }) => {
  const [loanding, setLoanding] = useState(true);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      ws: file(relativePath: { eq: "whatsapp.png" }) {
        childImageSharp {
            fluid(maxWidth: 50) {
                ...GatsbyImageSharpFluid
            }
        }
      }


    }
  `)

  useEffect(() => {
    setTimeout(() => { 
      setLoanding(false); 
    }, 700);
  }, []);

  let btn_ws = 'https://api.whatsapp.com/send?phone=584245149117&text=Hola%20Victor%20Figueira%20%20me%20gustaría%20hablar%20sobre%20los%20servicios%20de%20Metalinks%20'

if (typeof window !== `undefined`) {
  if( navigator.userAgent.match(/Android/i)
   || navigator.userAgent.match(/webOS/i)
   || navigator.userAgent.match(/iPhone/i)
   || navigator.userAgent.match(/iPad/i)
   || navigator.userAgent.match(/iPod/i)
   || navigator.userAgent.match(/BlackBerry/i)
   || navigator.userAgent.match(/Windows Phone/i)){
  
    btn_ws = 'https://api.whatsapp.com/send?phone=584245149117&text=Hola%20Victor%20Figueira%20%20me%20gustaría%20hablar%20sobre%20los%20servicios%20de%20Metalinks%20'
   }else{
    btn_ws = 'https://web.whatsapp.com/send?phone=584245149117&text=Hola%20Victor%20Figueira%20%20me%20gustaría%20hablar%20sobre%20los%20servicios%20de%20Metalinks%20'
  
   }
}

  return (

    <>
        {loanding
            ?
                <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle className="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#022d5d"/></svg></div>
            :
                ''
        }

        <Header 
            siteTitle={data.site.siteMetadata.title} 
            changeHeader={changeHeader}
        />
        
        <main>{children}</main>

        <a data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease" className='flotante' target="_blank" title="Escribeme via WhatsApp" href={btn_ws} ><img src={`${data.ws.childImageSharp.fluid.src}`} alt="logo whatsapp" border="0"/></a>
        <Footer changeHeader={changeHeader} />
    </>
  )
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
